/* Global Styles */
body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f9f9f9;
  }
  
  /* Home Page Styles */
  .home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .subtitle {
    font-size: 18px;
    color: #777;
  }
  
  .meme-section {
    margin: 30px 0;
    text-align: center;
  }
  
  .description {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .video-container {
    margin-top: 20px;
  }
  
  .video {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
  }
  
  .footer {
    margin-top: 40px;
    text-align: center;
    color: #777;
  }
  
