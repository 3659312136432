/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f4f4f4;
}

h1, h2 {
  color: #333;
}

/* Navbar Styles */
.navbar {
  background-color: #333;
  padding: 10px;
  text-align: center;
}

.nav-link {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  font-size: 18px;
}

.nav-link:hover {
  text-decoration: underline;
}

/* Container Styles */
.container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 28px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Button Styles */
.button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  background-color: #0056b3;
}

/* Video Styles */
.video-container {
  margin-top: 20px;
}

.video {
  width: 100%;
  border-radius: 10px;
}

/* Form Styles */
.contact-form {
  display: flex;
  flex-direction: column;
}

.input, .textarea {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.textarea {
  height: 150px;
  resize: none;
}

.button {
  align-self: flex-start;
}
